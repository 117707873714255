import { User } from '@api/models/user.entity';
import { Action, createReducer, on } from '@ngrx/store';

import { UsersActions, UsersSetUserAction } from './users.actions';

export const usersFeatureKey = 'users';

export interface UsersState {
	current: User;
}

export const initialState: UsersState = {
	current: null,
};

const setUserFunc = (state, { user }: UsersSetUserAction): UsersState => ({
	...state,
	current: user,
});

const deleteCurrentFunc = (state): UsersState => ({ ...state, current: null });

export const reducer = createReducer(
	initialState,
	on(UsersActions.setCurrent, setUserFunc),
	on(UsersActions.deleteCurrent, deleteCurrentFunc),
);

export function usersReducer(state = initialState, action: Action): UsersState {
	return reducer(state, action);
}
