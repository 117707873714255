import { Lang, Theme } from '@api/models/enums';
import { User } from '@api/models/user.entity';
import { createAction, props } from '@ngrx/store';

export interface UsersSetUserAction {
	user: User;
}

export interface UsersSetFileAction {
	file: File;
}

export interface UsersSetVolumeAction {
	volume: number;
}

export interface UsersSetThemeAction {
	theme: Theme;
}

export interface UsersSetLangAction {
	lang: Lang;
}

export class UsersActions {
	public static join = createAction('[Users] Join');

	public static setCurrent = createAction(
		'[Users] Set Current',
		props<UsersSetUserAction>(),
	);

	public static deleteCurrent = createAction('[Users] Delete Current');

	public static updateCurrent = createAction(
		'[Users] Update Current',
		props<UsersSetUserAction>(),
	);

	public static updateCurrentSuccess = createAction(
		'[Users] Update Current Success',
	);

	public static updateCurrentAvatar = createAction(
		'[Users] Update Current Avatar',
		props<UsersSetFileAction>(),
	);

	public static toonifyCurrentAvatar = createAction(
		'[Users] Toonify Current Avatar',
		props<UsersSetFileAction>(),
	);

	public static toonifyCurrentAvatarSuccess = createAction(
		'[Users] Toonify Current Avatar Success',
		props<UsersSetFileAction>(),
	);

	public static setVolume = createAction(
		'[Users] Set Volume',
		props<UsersSetVolumeAction>(),
	);

	public static setTheme = createAction(
		'[Users] Set Theme',
		props<UsersSetThemeAction>(),
	);

	public static setLang = createAction(
		'[Users] Set Lang',
		props<UsersSetLangAction>(),
	);
}
