import { createFeatureSelector, createSelector } from '@ngrx/store';

import { usersFeatureKey, UsersState } from './users.reducer';

const selectUsers = createFeatureSelector<UsersState>(usersFeatureKey);

export class UsersSelectors {
	public static current = createSelector(selectUsers, (state: UsersState) => {
		if (state && state.current) return state.current;
		return null;
	});

	public static currentId = createSelector(
		selectUsers,
		(state: UsersState) => {
			if (state && state.current) return state.current._id;
			return null;
		},
	);

	public static currentStats = createSelector(
		selectUsers,
		(state: UsersState) => {
			if (state && state.current) return state.current.stats;
			return null;
		},
	);

	public static currentAvatarUrl = createSelector(
		selectUsers,
		(state: UsersState) => {
			if (state && state.current) return state.current.avatarUrl;
			return null;
		},
	);

	public static currentResults = createSelector(
		selectUsers,
		(state: UsersState) => {
			if (state && state.current) return state.current.lastResults;
			return null;
		},
	);

	public static preferences = createSelector(
		selectUsers,
		(state: UsersState) => {
			if (!state.current) return null;
			return state.current.preferences;
		},
	);
}
