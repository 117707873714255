import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { Lang, Theme } from '@api/models/enums';
import { Preferences } from '@api/models/preferences.entity';
import { UserResult } from '@api/models/user-result.entity';
import { UserStats } from '@api/models/user-stats.entity';
import { User } from '@api/models/user.entity';
import {
	UsersActions,
	UsersSetFileAction,
} from '@bussiness/store/features/users/users.actions';
import { UsersSelectors } from '@bussiness/store/features/users/users.selectors';
import { UserUpdatedEvent } from '@core/models/users/events/user.events';
import { UsersSockets } from '@core/services/sockets/users.sockets';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

@Injectable()
export class UsersFacade {
	// Actions
	public toonifyCurrentAvatarSuccessAction$: Observable<UsersSetFileAction> =
		this.actions$.pipe(ofType(UsersActions.toonifyCurrentAvatarSuccess));

	// Selectors
	public currentId$: Observable<string> = this.store.select(
		UsersSelectors.currentId,
	);

	public currentAvatarUrl$: Observable<string> = this.store.select(
		UsersSelectors.currentAvatarUrl,
	);

	public currentStats$: Observable<UserStats> = this.store.select(
		UsersSelectors.currentStats,
	);

	public currentGameResults$: Observable<Array<UserResult>> =
		this.store.select(UsersSelectors.currentResults);

	public current$: Observable<User> = this.store.select(
		UsersSelectors.current,
	);

	public preferences$: Observable<Preferences> = this.store.select(
		UsersSelectors.preferences,
	);

	constructor(
		private store: Store,
		private actions$: Actions,
		private usersSockets: UsersSockets,
	) {
		this.usersSockets.updated$.subscribe(({ user }: UserUpdatedEvent) =>
			this.store.dispatch(UsersActions.setCurrent({ user })),
		);
	}

	public toonifyCurrentAvatar(file: File): void {
		this.store.dispatch(UsersActions.toonifyCurrentAvatar({ file }));
	}

	public updateCurrent(user: User): void {
		this.store.dispatch(UsersActions.updateCurrent({ user }));
	}

	public updateCurrentAvatar(file: File): void {
		this.store.dispatch(UsersActions.updateCurrentAvatar({ file }));
	}

	public setVolume(volume: number): void {
		this.store.dispatch(UsersActions.setVolume({ volume }));
	}

	public setTheme(theme: Theme): void {
		this.store.dispatch(UsersActions.setTheme({ theme }));
	}

	public setLang(lang: Lang): void {
		this.store.dispatch(UsersActions.setLang({ lang }));
	}
}
